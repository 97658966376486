import { RadioCard, RadioCardProps } from '@almbrand/radiocard';
import { ThemeUnion } from '@almbrand/systemicons/dist/cjs/types/icon';
import { ThemeNames } from 'constantsValues';
import { PageContext } from 'context/PageContext';
import { memo, useContext } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setCurrentRadioState } from 'store/slices/radioCardSlice';

export interface RadioCardComponentProps extends RadioCardProps<ThemeUnion> {
	index: number;
	register: any;
	contentType: string;
	stepContentId: number;
	submitStep?: () => void;
}

export const RadioCardComponent: React.FC<RadioCardComponentProps> = memo(
	({ index, register, submitStep, name, contentType, stepContentId, ...props }) => {
		const pageContext = useContext(PageContext);
		const { theme } = pageContext ?? {};
		const themeName = ThemeNames[theme];
		const currentRadioState = useAppSelector((state) => state.currentRadioState);
		const dispatch = useAppDispatch();
		const { onChange: onRadioChange, name: radioName } = register(stepContentId.toString());

		const handleOnChange = () => {
			dispatch(setCurrentRadioState({ index: index, name: name }));
			onRadioChange({
				target: {
					name: radioName,
					value: {
						id: name,
						value: props.value,
						label: props.title,
						index: index,
						type: contentType,
					},
				},
			});
			submitStep && submitStep();
		};

		return (
			<RadioCard
				{...props}
				themeName={themeName}
				checked={
					currentRadioState.some((state) => state.name === name && state.index === index) ?? props.checked
				}
				onChange={handleOnChange}
			/>
		);
	}
);

RadioCardComponent.displayName = 'RadioCardComponent';
