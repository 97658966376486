import { combineReducers } from '@reduxjs/toolkit';
import { DESTROY_SESSION } from './actionTypeConstants';

import calculatorFormSlice, { CalculatorFormState } from './slices/calculatorFormSlice';
import pageListSlice, { PageListState } from './slices/pageListSlice';
import radioCardSlice, { RadioCardState } from './slices/radioCardSlice';

export interface State {
	pages: PageListState;
	currentRadioState: RadioCardState[];
	calculatorForm: CalculatorFormState;
}

// Combine all reducers.
const appReducer = combineReducers({
	pages: pageListSlice,
	currentRadioState: radioCardSlice,
	calculatorForm: calculatorFormSlice,
});

export const rootReducer = (state: State | undefined, action: any) => {
	// Clear all data in redux store to initial.
	if (action.type === DESTROY_SESSION) state = undefined;

	return appReducer(state, action);
};
export default rootReducer;
