import { useAppDispatch } from 'store/hooks';
import { addFormItem } from 'store/slices/calculatorFormSlice';

export const useFormSubmit = () => {
	const dispatch = useAppDispatch();

	const onSubmit = async (fromData: any) => {
		if (fromData) {
			Object.keys(fromData).forEach((key, index) => {
				dispatch(
					addFormItem({
						key: key,
						item: { data: fromData[key], page: index.toString() },
					})
				);
			});
		}
	};

	return { onSubmit };
};
