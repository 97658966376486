import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface PageListState {
	contentArea: ContentAreas;
	numberOfActivePages: number;
}

const initialContent: Content = {
	content: {
		activePages: [],
		pageIndex: 0,
		pageLast: false,
		sectionIndex: 0,
		sectionLast: false,
	},
};

const initialState: PageListState = {
	contentArea: {
		campaignArea: initialContent,
		prePriceArea: initialContent,
		priceArea: initialContent,
		postPriceArea: initialContent,
	},
	numberOfActivePages: 0,
};

const pageListSlice = createSlice({
	name: 'PageListSlice',
	initialState,
	reducers: {
		addPage: (
			state,
			action: PayloadAction<{ contentArea: keyof ContentAreas; page: StepBlock; index?: number }>
		) => {
			const { contentArea, page, index } = action.payload;
			if (state.contentArea[contentArea].content.activePages.some((p) => p.contentId === page.contentId))
				return console.log('Duplicate page');

			if (state.contentArea[contentArea].content.sectionLast) return console.log('Last page');

			return {
				...state,
				contentArea: {
					...state.contentArea,
					[contentArea]: {
						content: {
							...state.contentArea[contentArea].content,
							activePages: [
								...state.contentArea[contentArea].content.activePages,
								{ ...page, navigationIndex: state.numberOfActivePages },
							],
							pageIndex: state.contentArea[contentArea].content.pageIndex + 1,
						},
					} as Content,
				},
				numberOfActivePages: state.numberOfActivePages + 1,
			};
		},

		updateLast: (
			state,
			action: PayloadAction<{ contentArea: keyof ContentAreas; lastPage?: boolean; lastSection?: boolean }>
		) => {
			const { contentArea, lastPage, lastSection } = action.payload;
			return {
				...state,
				contentArea: {
					...state.contentArea,
					[contentArea]: {
						content: {
							...state.contentArea[contentArea].content,
							pageLast: lastPage ?? state.contentArea[contentArea].content.pageLast,
							sectionLast: lastSection ?? state.contentArea[contentArea].content.sectionLast,
						},
					},
				},
			};
		},

		incrementIndex: (
			state,
			action: PayloadAction<{ contentArea: keyof ContentAreas; whatIndex: 'page' | 'section' }>
		) => {
			const { contentArea, whatIndex } = action.payload;
			return {
				...state,
				contentArea: {
					...state.contentArea,
					[contentArea]: {
						content: {
							...state.contentArea[contentArea].content,
							pageIndex:
								whatIndex === 'page'
									? state.contentArea[contentArea].content.pageIndex + 1
									: state.contentArea[contentArea].content.pageIndex,

							sectionIndex:
								whatIndex === 'section'
									? state.contentArea[contentArea].content.sectionIndex + 1
									: state.contentArea[contentArea].content.sectionIndex,
						},
					} as Content,
				},
			};
		},

		resetIndex: (
			state,
			action: PayloadAction<{ contentArea: keyof ContentAreas; whatIndex: 'page' | 'section' }>
		) => {
			const { contentArea, whatIndex } = action.payload;
			return {
				...state,
				contentArea: {
					...state.contentArea,
					[contentArea]: {
						content: {
							...state.contentArea[contentArea].content,
							[`${whatIndex}Index`]: 0,
						},
					} as Content,
				},
			};
		},
	},
});

export const { addPage, incrementIndex, resetIndex, updateLast } = pageListSlice.actions;

export default pageListSlice.reducer;
