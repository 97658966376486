export const loadStateFromSessionStorage = () => {
	try {
		const serializedState = sessionStorage.getItem('redux-state');
		if (serializedState === null) {
			return undefined; // No state in sessionStorage
		}
		return JSON.parse(serializedState);
	} catch (err) {
		console.error('Failed to load sessionStorage', err);
		return undefined; // Handle errors e.g. JSON parsing errors
	}
};

export const saveStateToSessionStorage = (state: any) => {
	try {
		const serializedState = JSON.stringify(state);
		sessionStorage.setItem('redux-state', serializedState);
	} catch (err) {
		console.error('Failed to save sessionStorage', err);
	}
};

export const removeStateFromSessionStorage = () => {
	try {
		sessionStorage.removeItem('redux-state');
	} catch (err) {
		console.error('Failed to remove sessionStorage', err);
	}
};
