import { Footer } from '@almbrand/footer';

export interface PartiellFooterComponentProps {
	policyLinks: Link[];
}

export const PartiellFooterComponent: React.FC<PartiellFooterComponentProps> = ({ policyLinks }) => {
	const mapedPolicyLinks = policyLinks
		? policyLinks.map((link) => ({ text: link.text, url: link.url }))
		: [{ text: '', url: '' }];

	return (
		<Footer
			partiellFooter
			policyLinks={mapedPolicyLinks}
		/>
	);
};
