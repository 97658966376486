import Cookies from 'js-cookie';

export const saveStateToCookie = async (state: any) => {
	const cookieValue = JSON.stringify(state);
	const minutesUntilExpire = 20;
	const expires = minutesUntilExpire / (24 * 60); // Convert minutes to days
	await Cookies.set('redux-state', cookieValue, { expires });
};

export const loadStateFromCookie = () => {
	const cookieValue = Cookies.get('redux-state');
	if (cookieValue) {
		return JSON.parse(cookieValue);
	}
	return undefined;
};

export const removeStateFromCookie = () => {
	Cookies.remove('redux-state');
};
