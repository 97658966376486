import axios, { AxiosInstance } from 'axios';

export const apiInstance = (): AxiosInstance => {
	const axiosInstance = axios.create({
		timeout: 100000,
	});

	// add X-Site-Name header to requests
	const theme = 'CODAN';
	axios.defaults.headers.common['X-Site-Name'] = theme;

	// Add the error handling interceptor
	axiosInstance.interceptors.response.use(
		(response) => response.data,
		(error) => {
			console.error('API error', error);
			throw error;
		}
	);

	return axiosInstance;
};
