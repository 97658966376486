import { Container } from '@almbrand/container';
import { ContainerLayout, HeaderComponent, PartiellFooterComponent } from 'components/4-habitats';
import { PageContext } from 'context/PageContext';
import { useContext } from 'react';

export const ContentPage: React.FC<PageModel> = () => {
	const pageContext = useContext(PageContext);

	const { content, headerContent } = pageContext ?? {};

	return (
		<div>
			<Container
				desktopWidth='1600'
				tabletWidth='1280'
			>
				<HeaderComponent {...headerContent} />
			</Container>

			{content?.items?.map((component) =>
				component.content.contentType === 'FooterBlock' ? (
					<PartiellFooterComponent
						policyLinks={component.content.policyLinks}
						key={component?.content?.contentId}
					/>
				) : (
					<ContainerLayout
						component={component}
						key={component?.content?.contentId}
					/>
				)
			)}
		</div>
	);
};
