import { Iframe } from '@almbrand/iframe';
import { LoadingSpinner } from '@almbrand/loadingspinner';
import { useState } from 'react';
import style from './IframeComponent.module.scss';

export interface IframeComponentProps {
	title: string;
	source: string;
	contentId: number | string;
	hight?: string;
	width?: string;
}

export const IframeComponent: React.FC<IframeComponentProps> = ({
	title,
	source,
	contentId,
	hight = '100%',
	width = '100%',
}) => {
	const [iframeLoaded, setIframeLoaded] = useState(false);

	return (
		<div className={style.IframeComponent__wrapper}>
			<div className={style.IframeComponent__content}>
				{!iframeLoaded && (
					<div className={style.IframeComponent__spinner}>
						<LoadingSpinner
							color='primary'
							style='slow'
							type='dottedCircle'
						/>
					</div>
				)}
				<Iframe
					id={contentId}
					src={source}
					title={title}
					onIframeLoaded={() => setIframeLoaded(true)}
					width={width}
					height={hight}
				/>
			</div>
		</div>
	);
};
