import { Container } from '@almbrand/container';
import { Grid, GridCell } from '@almbrand/grid';
import { HeaderComponent, MultiplePageTemplate } from 'components';
import { PageContext } from 'context/PageContext';
import { memo, useContext } from 'react';

export const ProductCalculatorPage: React.FC<PageModel> = memo(() => {
	const pageContext = useContext(PageContext);

	const { headerContent, pageModel } = pageContext ?? {};

	return (
		<>
			<Container
				desktopWidth='1600'
				tabletWidth='1280'
			>
				<HeaderComponent {...headerContent} />
			</Container>
			<Container
				desktopWidth='1600'
				tabletWidth='fullWidth'
				mobileWidth='fullWidth'
			>
				<Container
					desktopWidth='2500'
					tabletWidth='1600'
					mobileWidth='fullWidth'
				>
					<Grid
						container
						nogutter
					>
						<GridCell desktopWidth='9col'>
							<MultiplePageTemplate pageModel={pageModel} />
						</GridCell>
					</Grid>
				</Container>
			</Container>
		</>
	);
});
