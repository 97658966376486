export const purifyApiUrl = (url: string, contentDeliveryApiUrl: string) => {
	if (!contentDeliveryApiUrl) {
		throw new Error('Domain URL or Content Delivery API URL is missing');
	}

	// Remove editor specific parts of URL to render site. To be replaced by preview mode
	const purifiedUrl = url.replace('/EPiServer/CMS/Content', '').replace(/,,[^/]*\//g, '');

	return contentDeliveryApiUrl + `?contentUrl=${encodeURIComponent(purifiedUrl)}&matchExact=true&config=*`;
};
